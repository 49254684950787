body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background-color: #001f33;
  color: #02ddf3;
  overflow-x: hidden;
}
a {
  color: #02ddf3;
  text-decoration: none;
}
a:hover {
  color: #02ddf3;
  text-decoration: underline;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #02ddf3;
}
.brand_logo {
  width: 165px;
}
.main_navbar {
  background-color: #001624 !important;
  border-bottom: 1px solid #0c4751;
  z-index: 10;
  transition: transform 0.3s;
}
.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: -40px;
  background-color: #001624 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 40%);
  z-index: 999;
  transform: translateY(40px);
}
.navbar_right {
  display: flex;
  align-items: center;
}
.navbar_right li a {
  color: #5c7086;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
.navbar_right li a:hover,
.navbar_right li a.active {
  color: #fff !important;
}
.navbar_right li a.active::after,
.navbar_right li a:hover::after {
  content: "";
  background: #02cce1;
  width: 90%;
  height: 1px;
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.navbar_right button.primary_btn {
  font-size: 16px;
  padding: 10px 25px;
  margin-left: 15px;
}
.navbar_right .primary_btn:after,
.navbar_right .primary_btn:before {
  content: " ";
  width: 30px;
  height: 30px;
}
.page_header {
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../src/assets/images/banner_bg.png") no-repeat center top;
}
.page_header > .container > .row:nth-child(1) {
  z-index: 10;
  position: relative
}
.page_header h1 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  margin: 80px 0 30px;
  color: #fff;
}
.page_header h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.banner_img {
  text-align: center;
}
.timer_panel {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  margin: 10px 0 20px;
}
.timer_time {
  font-size: 60px;
  text-align: center;
  font-weight: 900;
  color: #fff;
  line-height: 60px;
}
span.timer_label {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  color: #00a5ff;
}
span.timer_dots {
  font-size: 60px;
  color: #fff;
  line-height: 56px;
}
.timer_panel > span {
  margin: 5px 20px 0 0;
}
.presale_box {
  border: 1px solid #006a99;
  background: rgb(0, 31, 51);
  background: linear-gradient(
    0deg,
    rgba(0, 31, 51, 1) 0%,
    rgba(0, 46, 71, 1) 100%
  );
  border-radius: 30px;
  padding: 30px;
  margin-top: -100px;
  position: relative;
  z-index: -1;
}
.presale_box_inner,
.presale_box_outer {
  height: 100%;
  position: relative;
}
.presale_box_inner::before,
.presale_box_inner::after,
.presale_box_outer::before,
.presale_box_outer::after {
  content: "";
  background: url("../src/assets/images/presale_box_corner.png");
  width: 45px;
  height: 48px;
  position: absolute;
}
.presale_box_inner::before {
  top: -32px;
  left: -33px;
}
.presale_box_inner::after {
  top: -35px;
  right: -30px;
  transform: rotate(90deg);
}
.presale_box_outer::before {
  bottom: -34px;
  left: -30px;
  transform: rotate(-90deg);
}
.presale_box_outer::after {
  bottom: -32px;
  right: -33px;
  transform: rotate(180deg);
}
.presale_box h2 {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
  color: #008bd0;
}
.presale_box h3 {
  font-size: 16px;
  font-weight: 400;
  color: #02ddf3;
}
section {
  padding: 80px 0;
  overflow: hidden;
}
.main_title {
  font-size: 46px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
.main_title span {
  color: #00a5ff;
}
.inner_para {
  font-size: 18px;
}
.rotating_globe_div {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rotating_globe_div::before {
  content: "";
  background: url("../src/assets/images/shadow_01.png") no-repeat center;
  width: 932px;
  height: 984px;
  position: absolute;
}
.rotating_globe {
  animation: animrotate 50s linear infinite;
}
@keyframes animrotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.rotating_globe_vector {
  position: absolute;
  animation: animrotateone 50s linear infinite;
}
@keyframes animrotateone {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rotating_bonjour_logo {
  position: absolute;
  left: 0;
  right: 75px;
  margin: 0 auto;
}
.primary_btn {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  padding: 18px 50px;
  transition: all 1s;
  border-radius: 20px;
  background: rgb(0, 31, 51);
  background: linear-gradient(
    0deg,
    rgba(0, 31, 51, 1) 0%,
    rgba(0, 46, 71, 1) 100%
  );
  border: 1px solid #006a99;
  z-index: 10;
}
.primary_btn.address_btn {
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
}
.primary_btn.address_btn:after {
  top: -1px;
  left: -5px;
}
.primary_btn.address_btn:before {
  bottom: -1px;
  right: -1px;
}
.primary_btn:before {
  bottom: -2px;
  right: -2px;
  border-bottom: 3px solid #00a3ff;
  border-right: 3px solid #00a3ff;
  border-radius: 0 0 20px 0;
}
.primary_btn:after {
  top: -2px;
  left: -2px;
  border-top: 3px solid #00a3ff;
  border-left: 3px solid #00a3ff;
  border-radius: 20px 0 0 0;
}
.primary_btn:after,
.primary_btn:before {
  content: " ";
  width: 50px;
  height: 50px;
  position: absolute;
  transition: all 1s;
}
.primary_btn:hover {
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.primary_btn:hover:before,
.primary_btn:hover:after {
  width: 101.8%;
  height: 101.8%;
}
ul.dropdown-menu.walletdrop {
  background: #00101a;
}
ul.dropdown-menu.walletdrop li a {
  color: #fff;
  font-size: 14px;
}
ul.dropdown-menu.walletdrop li a:hover {
  background: #002237;
}
ul.dropdown-menu.walletdrop li a.active::after,  
ul.dropdown-menu.walletdrop li a:hover::after {
  display: none;
}
section.why_choose_section {
  overflow: unset;
}
.why_choose_box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 4px solid transparent;
  transition: 0.2s all;
  padding: 30px;
  cursor: default;
}
.why_choose_box_img {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.why_choose_box_img::before {
  content: "";
  background: url("../src/assets/images/shadow_02.png") no-repeat center top;
  width: 283px;
  height: 280px;
  position: absolute;
  top: -80px;
  left: -80px;
  right: 0;
  margin: 0 auto;
  z-index: -1;
}
.why_choose_box h4 {
  font-size: 26px;
  font-weight: 700;
  color: #fff;
}
.why_choose_box p {
  display: none;
  margin-bottom: 0;
}
.why_choose_box:hover p {
  display: block;
}
.why_choose_row {
  transition: all 1s;
  border-radius: 20px;
  background: rgb(0, 31, 51);
  background: linear-gradient(
    0deg,
    rgba(0, 31, 51, 1) 0%,
    rgba(0, 46, 71, 1) 100%
  );
  border: 1px solid #006a99;
  padding: 30px 15px;
  height: 260px;
  margin-top: 100px;
}
.why_choose_box:hover {
  background: rgb(0, 31, 51);
  background: linear-gradient(
    0deg,
    rgba(0, 31, 51, 1) 0%,
    rgba(0, 46, 71, 1) 100%
  );
  border: 4px solid #006a99;
  margin-top: -100px;
  border-radius: 30px;  
}
.token_allocation_section {
  background: #001625 url("../src/assets/images/token_allocation_bg.png")
    no-repeat center top;
  background-size: cover;
}
.mar_top_section {
  margin-top: -200px;
  padding-top: 280px;
  padding-bottom: 450px;
}
.token_details {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 30px 0;
}
.token_details > div {
  width: 33%;
  margin-bottom: 30px;
}
.token_details > div label {
  color: #fff;
  font-size: 18px;
}
.token_details > div p {
  margin-bottom: 0;
  font-size: 18px;
}
.token_sale_img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.token_sale_img::before {
  content: "";
  background: url("../src/assets/images/shadow_03.png") no-repeat center;
  width: 902px;
  height: 923px;
  position: absolute;
  z-index: -1;
}
.token_dis_div h2 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
.token_dis_div ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.token_dis_div ul li {
  display: flex;
  margin-bottom: 20px;
}
.token_dis_div ul li span {
  font-size: 18px;
}
.token_dis_div ul li span:first-child {
  width: 50%;
}
section.roadmap_section {
  margin-top: -450px;
}
.roadmap_box {
  background: rgb(0, 31, 51);
  background: linear-gradient(
    0deg,
    rgba(0, 31, 51, 1) 0%,
    rgba(0, 46, 71, 1) 100%
  );
  border: 1px solid #006a99;
  border-radius: 30px;
  padding: 30px;
  transition: 0.2s all;
  cursor: default;
  position: relative;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.roadmap_box h2 {
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
}
.roadmap_box h3 {
  font-size: 16px;
  color: #02ddf3;
  font-weight: 400;
}
.roadmap_box h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.roadmap_box img {
  margin: 15px 0 30px;
  opacity: 1;
}
.roadmap_box .roadmap_box_top {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  transition: 0.5s;
}
.roadmap_box:hover .roadmap_box_top {
  transform: translateY(-50px);
}
.roadmap_box .roadmap_box_content {
  position: absolute;
  bottom: 50px;
  display: flex;
}
.roadmap_box .roadmap_box_content p {
  transition: 0.5s;
  opacity: 0;
  margin: 0;
}
.roadmap_box:hover .roadmap_box_content p {
  transform: translateY(10px);
  opacity: 1;
}
.roadmap_box:hover {
  min-height: 450px;
  border: 4px solid #006a99;
  box-shadow: 0 0 10px 3px rgb(0 148 228 / 35%);
}
.roadmap_box_wrap {
  min-height: 500px;
}
.faq_section {
  background: url("../src/assets/images/globe_bg.png") no-repeat top center;
  padding-bottom: 0;
}
.faq_wrap {
  background: #001625;
  margin-top: 120px;
  padding-bottom: 50px;
}
#faq_accodian {
  margin-top: -80px;
}
#faq_accodian .accordion-header button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  background: transparent;
  color: #fff;
  padding: 10px 15px 10px 30px;
}
#faq_accodian .accordion-header,
#faq_accodian .accordion-body {
  background-color: transparent;
}
#faq_accodian .accordion-item {
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  background: #001f33;
  background-clip: padding-box;
  border: 1px solid #006a99 !important;
  border-radius: 30px;
  transition: 0.2s all;
  overflow: unset;
  margin-bottom: 20px;
}
#faq_accodian .accordion-body {
  padding-top: 0;
}
#faq_accodian .accordion-body p {
  font-size: 16px;
  margin-bottom: 0;
  cursor: default;
  padding-left: 10px;
  max-width: 90%;
  color: #02d4ea;
}
.accordion-button:not(.collapsed)::after {
  background: url("./assets/images/minus_icon.png");
  transform: rotate(360deg);
}
.accordion-button::after {
  background: url("./assets/images/plus_icon.png");
  width: 34px;
  height: 34px;
}
.footer {
  background-color: #00101a;
  padding: 50px 0 30px;
}
.footer_panel_top {
  text-align: center;
  border-bottom: 1px solid #063554;
  padding-bottom: 20px;
}
.footer_panel_top h2 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}
.footer_panel_top p {
  font-size: 16px;
  max-width: 50%;
  margin: 0 auto 30px;
}
.footer_panel_top .input-group {
  width: 50%;
  margin: 0 auto 30px;
}
.footer_panel_top .input-group input {
  background: #001625;
  border: 1px solid #001625;
  color: #02d4ea;
  font-size: 18px;
  height: 65px;
  border-radius: 50px;
  padding-left: 30px;
  box-shadow: none !important;
}
.footer_panel_top .input-group input::placeholder {
  color: #02d4ea;
  font-size: 18px;
}
.footer_panel_top .input-group-text {
  background: #00273c;
  border-color: #00273c;
  text-transform: uppercase;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 50px;
}
.footer_panel_top a {
  font-size: 16px;
  color: #e6007a;
  white-space: normal;
  word-break: break-all;
}
.footer_social_links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
}
.footer_social_links li {
  margin-right: 15px;
}
.footer_social_links li a {
  width: 44px;
  height: 44px;
  background: #003a5f;
  border: 1px solid #003a5f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00101a;
  font-size: 24px;
  text-decoration: none;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #172026;
}
.footer_panel_bottom {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_list_links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.footer_list_links li {
  position: relative;
}
.footer_list_links li + li {
  margin-left: 30px;
}
.footer_list_links li::after {
  content: "";
  background: #fff;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: -15px;
}
.footer_list_links li:last-child::after {
  display: none;
}
.footer_list_links li a {
  color: #fff;
  font-size: 14px;
}
.footer_list_links li a:hover {
  text-decoration: none;
  color: #00a5ff;
}
.footer_panel_bottom p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0;
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: rgb(0, 31, 51);
  background: linear-gradient(
    0deg,
    rgba(0, 31, 51, 1) 0%,
    rgba(0, 46, 71, 1) 100%
  );
}
.primary_modal .modal-header {
  border-bottom: 1px solid #006a99;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  border: 2px solid #006a99;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}
.connect_wallet_button:hover {
  border-color: #00a5ff;
}
.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}
.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}
.btn-close:hover {
  opacity: 1;
}
.primary_modal a {
  text-decoration: none;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
  position: relative;
  background: url("../src/assets/images/banner_bg.png") no-repeat center; 
}
.title_flex {
  display: flex;
  text-align: center;
  margin-bottom: 30px;
  justify-content: space-between;
}
.buy_token_box {
  border: 1px solid #006a99;
  background: rgb(0, 31, 51);
  background: linear-gradient( 0deg, rgba(0, 31, 51, 1) 0%, rgba(0, 46, 71, 1) 100% );
  border-radius: 30px;
  padding: 30px;
  position: relative;
}
.buy_token_box_inner, 
.buy_token_box_outer {
  height: 100%;
  position: relative;
}
.buy_token_box_inner::before, 
.buy_token_box_inner::after, 
.buy_token_box_outer::before, 
.buy_token_box_outer::after {
  content: "";
  background: url("../src/assets/images/presale_box_corner.png");
  width: 45px;
  height: 48px;
  position: absolute;
}
.buy_token_box_inner::before {
  top: -32px;
  left: -33px;
}
.buy_token_box_inner::after {
  top: -35px;
  right: -30px;
  transform: rotate(90deg);
}
.buy_token_box_outer::before {
  bottom: -34px;
  left: -30px;
  transform: rotate(-90deg);
}
.buy_token_box_outer::after {
  bottom: -32px;
  right: -33px;
  transform: rotate(180deg);
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.buy_token_box form input.form-control {
  background: #001625;
  border: 1px solid #001625;
  color: #02d4ea;
  font-size: 18px;
  height: 50px;
  padding-left: 15px;
  box-shadow: none !important;
}
.buy_token_box form select.form-select {
  background: #001625 url("../src/assets/images/caret-down-fill.png") no-repeat right .75rem center;
  background-size: 13px 9px;
  border: 1px solid #001625;
  color: #02d4ea;
  font-size: 18px;
  height: 50px;
  padding-left: 15px;
  box-shadow: none !important;
}
.buy_token_box form label {
  color: #fff;
}
.buy_token_box form label span {
  color: #02ddf3;
}
.custom_table .rdt_TableHead,
.custom_table .rdt_TableHeadRow,
.custom_table .rdt_Table > div {
  background-color: #00101a !important;
  color: #fff !important;
}
.custom_table .rdt_TableRow,
.custom_table .rdt_Pagination {
  color: #ffffff;
  background-color: #001624;
}
a.hash_address {
  display: block;
  white-space: nowrap;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom_table .rdt_Pagination button {
  color: rgb(255 255 255 / 54%);
  fill: rgb(255 255 255 / 54%);
}
.custom_table .rdt_Pagination button:disabled {
  color: rgb(255 255 255 / 18%);
  fill: rgb(255 255 255 / 18%);
}
.cms_section h2 {
  font-size: 22px;
  margin: 30px 0 10px;
}
.cms_section p {
  font-size: 16px;
  color: #c7c7c7;
  margin-bottom: 0;
}
section.about_section {
  overflow: unset;
}


/* Media Queries */
@media screen and (max-width: 1380px) {
  .roadmap_box:hover .roadmap_box_top {
    transform: translateY(-60px);
  }
  .roadmap_box h2 {
    font-size: 24px;
  }
  .roadmap_box img {
    margin: 10px 0 15px;
    height: 70px;
  }
}
@media screen and (max-width: 1199px) {
  .presale_box {
    margin-top: 50px;
  }
  .navbar-toggler {
    background-color: #00a3ff;
  }
  .why_choose_box h4 {
    font-size: 20px;
  }
  .why_choose_box p {
    font-size: 16px;
  }
  .navbar_right li a.active::after,
  .navbar_right li a:hover::after {
    display: none;
  }
  .roadmap_box {
    margin-bottom: 30px;
  }
  .timer_time {
    font-size: 46px;
  }
  .presale_box h2 {
    font-size: 24px;
  }
  .main_title {
    font-size: 36px;
  }
  .primary_btn {
    font-size: 20px;
    padding: 16px 40px;
  }
  .main_navbar {
    position: fixed;
    width: 100%;
    top: 0;
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 40%);
    z-index: 999;
  }
  .inner_wrapper {   
    padding-top: 120px;
  }
}
@media screen and (max-width: 991px) {
  .mar_top_section {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 30px;
  }
  section.roadmap_section {
    margin-top: 0;
  }
  .why_choose_row {
    height: unset;
    margin-top: 0;
    padding: 0;
  }
  .why_choose_box {
    background: linear-gradient(
      0deg,
      rgba(0, 31, 51, 1) 0%,
      rgba(0, 46, 71, 1) 100%
    );
    border: 1px solid #006a99;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 30px;
  }
  .why_choose_box p {
    display: block;
  }
  .why_choose_box:hover {
    margin-top: 0;
    border: 1px solid #006a99;
  }
  .why_choose_row {
    justify-content: center;
    background: transparent;
    border: none;
  }
  .token_details > div {
    width: 50%;
  }
  .token_dis_div ul li span {
    font-size: 16px;
  }
  .token_dis_div ul li span:first-child {
    width: 70%;
  }
  .footer_panel_top p {
    max-width: 100%;
  }
  .footer_panel_top .input-group {
    width: 100%;
  }
  .page_header h1 {
    margin: 15px 0 30px;
  }
  .page_header {
    text-align: center;
  }
  .about_section,
  .why_choose_section,
  .roadmap_section {
    text-align: center;
  }
  .rotating_globe_div {
    margin-top: 30px;
  }
  .roadmap_box .roadmap_box_content p {
    opacity: 1;
  }
  .roadmap_box:hover .roadmap_box_content p,
  .roadmap_box:hover .roadmap_box_top {
    transform: unset;
  }
  .roadmap_box .roadmap_box_content {
    position: relative;
    bottom: unset;
  }
  .roadmap_box:hover {
    min-height: 400px;
    border: 1px solid #006a99;
    box-shadow: 0 0 10px 3px rgb(0 148 228 / 35%);
  }
  .roadmap_box h4 {
    font-size: 18px;
  }
  .roadmap_box .roadmap_box_top {
    align-items: center;
    width: 100%;
  }
  .timer_panel {
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .roadmap_box,
  .roadmap_box:hover {
    min-height: auto;
  }
  .footer_panel_bottom {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer_list_links {
    margin-bottom: 10px;
  }
  .footer_panel_top .input-group-text {
    font-size: 16px;
    padding: 10px 18px;
  }
  .footer_panel_top h2 {
    font-size: 24px;
  }
  #faq_accodian .accordion-header button {
    font-size: 18px;
  }
  #faq_accodian .accordion-body p,
  .footer_panel_top p,
  .roadmap_box .roadmap_box_content p {
    font-size: 14px;
  }
  .main_title {
    font-size: 24px;
  }
  .inner_para {
    font-size: 16px;
  }
  .page_header h1 {
    font-size: 28px;
    line-height: 36px;
  }
  .token_dis_div h2 {
    font-size: 24px;
  }
  .primary_btn {
    font-size: 16px;
    padding: 12px 40px;
  }
  .primary_btn:after,
  .primary_btn:before {
    content: " ";
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 575px) {
  .timer_panel > span {
    margin: 5px 5px 0 0;
  }
  span.timer_dots {
    font-size: 42px;
  }
  .timer_time {
    font-size: 32px;
  }
  span.timer_label {
    font-size: 11px;
  }
  .presale_box h2 {
    font-size: 20px;
  }
  .rotating_bonjour_logo {
    width: 150px;
  }
  .token_details > div {
    width: 100%;
    text-align: center;
  }
}